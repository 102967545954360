import { Howl } from "howler";

Howl.html5PoolSize = 200;

export const makeAudio = function(src) {
  let options = {
    preload: true,
    html5: true
  };
  if (typeof src !== "string") {
    options = {
      ...options,
      ...src
    };
  } else {
    options["src"] = src;
  }
  let playing = false;

  let audio = new Howl({
    ...options,
    onplayerror: function() {
      audio.once("unlock", function() {
        if (!playing) {
          playing = true;
          audio.play();
        }
      });
    },
    onplay: function() {
      playing = true;
    },
    onstop: function() {
      playing = false;
    }
  });

  return audio;
};
